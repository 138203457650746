import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {change, formValueSelector, getFormValues, reduxForm} from 'redux-form';
import { API_URL_WS_FORMULAIRE, APP_URL_APPLICATION } from '@amo/core/constants/constants.js'
import { FILL, postFill } from '../../../../redux/actions/app/fill/fill.actions';
import { postPay, POST_PAY } from '../../../../redux/actions/app/pay/pay.action';
import { postSaveDevis, SAVE_DEVIS } from '../../../../redux/actions/app/saveDevis/saveDevis.actions';
import { TARIF } from '../../../../redux/actions/app/tarif/tarif.actions';
import { setDevisOrSubscribe } from '../../../../redux/actions/app/ui/ui.actions';
import {
    getActifApporteurDefault,
    getAdresse1PersonnePrincipaleDemandeTarifInit,
    getAdresse2PersonnePrincipaleDemandeTarifInit,
    getAdresse3PersonnePrincipaleDemandeTarifInit,
    getadrIBANDemandeContratInit,
    getAnnulationPermisAntecedentsDemandeTarifInit,
    getAntecedentsDemandeTarifInit,
    getApporteur1Init,
    getApporteur2Init,
    getAssure3DerniersMoisVehiculeDemandeTarifInit,
    getBICDemandeContratInit,
    getBirthCityPersonnePrincipaleDemandeTarifInit,
    getBirthCityTuteurDemandeTarifInit,
    getBirthCountryPersonnePrincipaleDemandeTarifInit,
    getBirthCountryTuteurDemandeTarifInit,
    getBirthDepartmentPersonnePrincipaleDemandeTarifInit,
    getBirthDepartmentTuteurDemandeTarifInit,
    getBirthNamePersonnePrincipaleDemandeTarifInit,
    getBirthNameTuteurDemandeTarifInit,
    getCarteGriseFrancaiseVehiculeDemandeTarifInit,
    getCivilitePersonneMoraleTarif,
    getCivilitePersonnePrincipaleDemandeTarifInit,
    getCiviliteTuteurDemandeTarifInit,
    getCodePostalGarageVehiculeDemandeTarifInit,
    getCodePromoDemandeTarifInit,
    getConcurrentCodePromoInit,
    getConcurrentCommentaireConcurrentInit,
    getConcurrentPrimeConcurrentInit,
    getCondamnationAlcoolemieAntecedentsDemandeTarifInit,
    getCondamnationConduiteSansAssuranceAntecedentsDemandeTarifInit,
    getCondamnationDelitFuiteAntecedentsDemandeTarifInit,
    getCondamnationStupefiantsAntecedentsDemandeTarifInit,
    getConducteurMineurPersonnePrincipaleDemandeTarifInit,
    getConnuCommentInit,
    getCPDemandeContratInit,
    getcpIBANDemandeContratInit,
    getCPPersonnePrincipaleDemandeTarifInit,
    getCrmAuto50Plus3AnsPersonnePrincipaleDemandeTarifInit,
    getCrmMoto50Plus3AnsPersonnePrincipaleDemandeTarifInit,
    getCSMInit,
    getDateHeureEffetContratInit,
    getDateMECVehiculeDemandeTarif,
    getDateNaissancePersonnePrincipaleDemandeTarifInit,
    getDateNaissanceTuteurDemandeTarifInit,
    getDejaClientAntecedentsPersonnePrincipaleDemandeTarifInit,
    getDejaClientDemandeTarifInit,
    getDevisHamonAssureurActuelIdDemandeContratInit,
    getDevisHamonAssureurActuelIdDemandeTarifInit,
    getDevisHamonAssureurActuelNameDemandeContratInit,
    getDevisHamonAssureurActuelNameDemandeTarifInit,
    getDevisHamonContratActuelDemandeContratInit,
    getDevisHamonContratActuelDemandeTarifInit,
    getDevisHamonDemandeContratInit,
    getDevisHamonDemandeTarifInit,
    getDevoirAccEqpDevoirConseilDemandeTarifInit,
    getDevoirASS2DevoirConseilDemandeTarifInit,
    getDevoirASS3DevoirConseilDemandeTarifInit,
    getDevoirDommageDevoirConseilDemandeTarifInit,
    getDevoirICDevoirConseilDemandeTarifInit,
    getDevoirMontantAccEqpDevoirConseilDemandeTarifInit,
    getDevoirRachatDevoirConseilDemandeTarifInit,
    getDevoirVolDevoirConseilDemandeTarifInit,
    getEmailSouscripteurTarif,
    getFormuleChoisiePoliceDemandeTarifInit,
    getFractionnementPoliceDemandeTarifInit,
    getFraisRecurrentsApporteur,
    getGPDemandeTarifInit,
    getHasFraisRecurrentsApporteur,
    getHonorairesApporteur,
    getIBANDemandeContratInit,
    getIDDevisDevisInit,
    getIdentifiantSRAVehiculeDemandeTarifInit,
    getIdSocietyPersonneMoraleTarif,
    getImmatriculationVehiculeDemandeTarifInit,
    getLegalStatusPersonneMoraleTarif,
    getListePermisPersonnePrincipaleDemandeTarifInit,
    getListeSinistresAntecedentsDemandeTarifInit,
    getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit,
    getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit,
    getNbMoisReferenceAutoPersonnePrincipaleDemandeTarifInit,
    getNbMoisReferenceMotoPersonnePrincipaleDemandeTarifInit,
    getNomPersonneMoraleTarif,
    getNomPersonnePrincipaleDemandeTarifInit,
    getNomTitulaireDemandeContratInit,
    getNomTuteurDemandeTarifInit,
    getNumPermisSouscripteurTarif,
    getOptinNewsletterDemandeDevisInit,
    getOptinUtilisationDonneesDemandeDevisInit,
    getOptionsDemandeTarifInit,
    getOptionsFranchiseDemandeTarifInit,
    getOptionsListeDemandeTarifInit,
    getPermisA1PersonnePrincipaleDemandeTarifInit,
    getPermisA2PersonnePrincipaleDemandeTarifInit,
    getPermisAMPersonnePrincipaleDemandeTarifInit,
    getPermisAPersonnePrincipaleDemandeTarifInit,
    getPermisBPersonnePrincipaleDemandeTarifInit,
    getPersonneMoraleDemandeTarifInit,
    getPersonnePrincipaleIsSouscripteurDemandeTarifInit,
    getPossessionPlusDe3MoisVehiculeDemandeTarifInit,
    getPrenomPersonnePrincipaleDemandeTarifInit,
    getPrenomTuteurDemandeTarifInit,
    getRealisateurDemandeTarifInit,
    getSignatureEmailDemandeContratInit,
    getSignaturePhoneDemandeContratInit,
    getSourceDemandeTarifInit,
    getState,
    getSuspensionPermisAntecedentsDemandeTarifInit,
    getTauxApporteur,
    getTelPortableSouscripteurTarif,
    getTiersPayeurBirthCityDemandeContratInit,
    getTiersPayeurBirthCountryDemandeContratInit,
    getTiersPayeurBirthDateDemandeContratInit,
    getTiersPayeurBirthNameDemandeContratInit,
    getTiersPayeurDemandeContratInit,
    getTiersPayeurFirstNameDemandeContratInit,
    getTiersPayeurLastNameDemandeContratInit,
    getTiersPayeurRelationshipDemandeContratInit,
    getTiersPayeurThirdPayerReasonDemandeContratInit,
    getTiersPayeurTypeDemandeContratInit,
    getToken,
    getTuteurDemandeTarifInit,
    getUsageNonLivraisonVehiculeDemandeTarifInit,
    getUsageVehiculeDemandeTarif,
    getValeurCrmAutoPersonnePrincipaleDemandeTarifInit,
    getValeurCrmMotoPersonnePrincipaleDemandeTarifInit,
    getValeurVehiculeVehiculeDemandeTarifInit,
    getVehiculeImmatriculeVehiculeDemandeTarifInit,
    getVilleGarageVehiculeDemandeTarifInit,
    getvilleIBANDemandeContratInit,
    getVillePersonnePrincipaleDemandeTarifInit,
    getVINVehiculeDemandeTarifInit,
} from '../../../../redux/selectors/init/init.selectors';
import {
    getDevisOrSubscribe,
    getErrorEntity,
    getLoadingEntity,
    getMaxStep,
    getPrixTotal,
    getPrixTotalPromo,
    getStep,
    getUtmSource,
} from '../../../../redux/selectors/ui/ui.selectors';
import {
    getCylindreeVehicule,
    getMarqueIdVehicule,
    getMarqueVehicule,
    getModeleVehicule,
    getTypeVehicule,
    getVehicule,
} from '../../../../redux/selectors/vehicule/vehicule.selectors';
import { normalizeTel } from '@amo/core/utils/normalize';
import InitFormulaire from './InitFormulaire';
import RouteStep from './RouteStep';
import {getDateAchatVehiculeDemandeTarif} from "formulaire-direct/src/redux/selectors/init/init.selectors.js";

moment.locale('fr');

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');
    const loadingTarif = getLoadingEntity(state, TARIF) === false;
    const isDevisOrDevisOrange = getState(state) === 'devis' || getState(state) === 'devis_orange';

    return {
        actifApporteur: getActifApporteurDefault(state),
        step: getStep(state),
        ibanIsCheck: Boolean(selector(state, 'DemandeContrat[BIC]')),
        prelevement_auto: selector(state, `prelevement_auto`) === '1',
        routes: RouteStep,
        permis: selector(state, 'DemandeTarif[ListePersonnes][0][ListePermis]'),
        vehicule: getVehicule(state),
        dateNaissance: selector(state, 'DemandeTarif[ListePersonnes][0][DateNaissance]'),
        realisateur: getRealisateurDemandeTarifInit(state),
        source: getSourceDemandeTarifInit(state),
        utm_source: getUtmSource(state),
        apporteur1: getApporteur1Init(state),
        apporteur2: getApporteur2Init(state),
        IDDevis: getIDDevisDevisInit(state),
        maxStep: getMaxStep(state),
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        querySaveDevis: `${API_URL_WS_FORMULAIRE}/save_devis/${getToken(state)}`,
        queryPay: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
        queryTarif: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        tarifLoaded: loadingTarif,
        payLoaded: getLoadingEntity(state, POST_PAY),
        saveDevisLoading: getLoadingEntity(state, SAVE_DEVIS),
        saveDevisError: getErrorEntity(state, SAVE_DEVIS),
        tarifError: getErrorEntity(state, TARIF),
        fillLoading: getLoadingEntity(state, FILL),
        devisOrSubscribe: getDevisOrSubscribe(state),
        isDevisOrange: getState(state) === 'devis_orange',
        has_frais: getHasFraisRecurrentsApporteur(state),
        url_pay: {
            token: getToken(state),
            url_return: `${APP_URL_APPLICATION}/paiement`,
        },
        fractionnement: selector(state, `DemandeTarif[Police][FractionnementChoisi]`),
        identifiantSRA: selector(state, `DemandeTarif[Vehicule][IdentifiantSRA]`),
        prixTotal: loadingTarif ? getPrixTotal(state) : null,
        prixTotalPromo: loadingTarif ? getPrixTotalPromo(state) : null,
        initialValues: {
            redirection: false,
            cgv: false,
            /* UI */
            prelevement_auto: getcpIBANDemandeContratInit(state) ? '1' : '0',
            'postalCodeCity-lieuStationnement': !!getCodePostalGarageVehiculeDemandeTarifInit(state),
            'postalCodeCity-coordonnees': !!getCPPersonnePrincipaleDemandeTarifInit(state),
            'postalCodeCity-villeIban': !!getCPDemandeContratInit(state),
            assure_moto: getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state) ? '1' : getValeurCrmMotoPersonnePrincipaleDemandeTarifInit(state) ? '0' : undefined,
            assure_auto: getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state) ? '1' : getValeurCrmAutoPersonnePrincipaleDemandeTarifInit(state) ? '0' : undefined,
            sinistre_auto: isDevisOrDevisOrange
                ? getListeSinistresAntecedentsDemandeTarifInit(state) &&
                  getListeSinistresAntecedentsDemandeTarifInit(state).length > 0
                    ? '1'
                    : '0'
                : undefined,
            typeVehicule: getApporteur2Init(state)?.codeBelair === '864160' ? '50' : (getApporteur1Init(state)?.codeBelair === '323284' ? 'moto' : getTypeVehicule(state)),
            marqueIdVehicule: getApporteur2Init(state)?.codeBelair === '864160' ? 636 : (getApporteur1Init(state)?.codeBelair === '323284' ? 315 : getMarqueIdVehicule(state)),
            marqueVehicule: getApporteur2Init(state)?.codeBelair === '864160' ? 'MOB-ION' : (getApporteur1Init(state)?.codeBelair === '323284' ? 'DUCATI' : getMarqueVehicule(state)),
            modeleVehicule: getModeleVehicule(state),
            cylindreeVehicule: getCylindreeVehicule(state),
            options: getOptionsDemandeTarifInit(state),
            optionsFranchise: getOptionsFranchiseDemandeTarifInit(state),
            optionsListe: getOptionsListeDemandeTarifInit(state),
            titulaireCarteGrise:
                !_.isEmpty(getTuteurDemandeTarifInit(state)) ||
                !getDateNaissancePersonnePrincipaleDemandeTarifInit(state)
                    ? undefined
                    : getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state) &&
                      getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state) !== 'false'
                    ? '1'
                    : '2',
            /* Formulaire */
            DemandeDevis: {
                ConnuComment: getConnuCommentInit(state),
                OptinNewsletter: getOptinNewsletterDemandeDevisInit(state),
                OptinUtilisationDonnees: getOptinUtilisationDonneesDemandeDevisInit(state),
            },
            DemandeContrat: {
                Signature: selector(state, `DemandeContrat[Signature]`) ?? false,
                NomTitulaire:
                    getNomTitulaireDemandeContratInit(state) !== 'undefined undefined'
                        ? getNomTitulaireDemandeContratInit(state)
                        : `${getNomPersonnePrincipaleDemandeTarifInit(
                              state,
                          )} ${getPrenomPersonnePrincipaleDemandeTarifInit(state)}`,
                adrIBAN: getadrIBANDemandeContratInit(state) || getAdresse1PersonnePrincipaleDemandeTarifInit(state),
                villeIBAN: getvilleIBANDemandeContratInit(state) || getVillePersonnePrincipaleDemandeTarifInit(state),
                cpIBAN: getcpIBANDemandeContratInit(state) || getCPPersonnePrincipaleDemandeTarifInit(state),
                SignatureEmail: getSignatureEmailDemandeContratInit(state) || getEmailSouscripteurTarif(state),
                SignaturePhone: getSignaturePhoneDemandeContratInit(state) || getTelPortableSouscripteurTarif(state),
                DateHeureEffet: getDevisHamonDemandeContratInit(state)
                    ? moment(getDateHeureEffetContratInit(state), 'DD/MM/YYYY HH:mm')
                    : moment().add(15, 'minutes'),
                IBAN: getIBANDemandeContratInit(state),
                BIC: getBICDemandeContratInit(state),
                VIN: getVINVehiculeDemandeTarifInit(state),
                ModePaiementTerme: getcpIBANDemandeContratInit(state) ? 'P' : 'B',
                DevisHamon: getDevisHamonDemandeContratInit(state) ? '1' : null,
                DevisHamonAssureurActuel:
                    getDevisHamonAssureurActuelNameDemandeContratInit(state) &&
                    getDevisHamonAssureurActuelIdDemandeContratInit(state)
                        ? `${getDevisHamonAssureurActuelIdDemandeContratInit(
                              state,
                          )}|${getDevisHamonAssureurActuelNameDemandeContratInit(state)}`
                        : null,
                DevisHamonAssureurActuelName: getDevisHamonAssureurActuelNameDemandeContratInit(state),
                DevisHamonAssureurActuelId: getDevisHamonAssureurActuelIdDemandeContratInit(state),
                DevisHamonContratActuel: getDevisHamonContratActuelDemandeContratInit(state),
                TiersPayeur: !_.isEmpty(getTiersPayeurDemandeContratInit(state))
                    ? {
                          Type: getTiersPayeurTypeDemandeContratInit(state) || 'P',
                          LastName: getTiersPayeurLastNameDemandeContratInit(state),
                          BirthName: getTiersPayeurBirthNameDemandeContratInit(state),
                          FirstName: getTiersPayeurFirstNameDemandeContratInit(state),
                          BirthDate: getTiersPayeurBirthDateDemandeContratInit(state),
                          BirthCountry: getTiersPayeurBirthCountryDemandeContratInit(state),
                          BirthCity: getTiersPayeurBirthCityDemandeContratInit(state),
                          Relationship: getTiersPayeurRelationshipDemandeContratInit(state),
                          ThirdPayerReason: getTiersPayeurThirdPayerReasonDemandeContratInit(state),
                      }
                    : {},
            },
            DemandeTarif: {
                CSM: getCSMInit(state),
                GoodbyePack: getGPDemandeTarifInit(state),
                DevisHamon: getDevisHamonDemandeTarifInit(state),
                DevisHamonAssureurActuelName: getDevisHamonAssureurActuelNameDemandeTarifInit(state),
                DevisHamonAssureurActuelId: getDevisHamonAssureurActuelIdDemandeTarifInit(state),
                DevisHamonContratActuel: getDevisHamonContratActuelDemandeTarifInit(state),
                CodePromo: {
                    CodePromo: getCodePromoDemandeTarifInit(state),
                    Concurrent: getConcurrentCodePromoInit(state),
                    PrimeConcurrent: getConcurrentPrimeConcurrentInit(state),
                    CommentaireConcurrent: getConcurrentCommentaireConcurrentInit(state),
                },
                Police: {
                    FractionnementChoisi: getFractionnementPoliceDemandeTarifInit(state) || 'M',
                    FormuleChoisie: getFormuleChoisiePoliceDemandeTarifInit(state),
                    IdApporteur1: getApporteur1Init(state)?.codeBelair,
                    IdApporteur2: getApporteur2Init(state)?.codeBelair,
                    Honoraires: getHonorairesApporteur(state),
                    TauxApporteur1: getTauxApporteur(state),
                    FraisRecurrents: getFraisRecurrentsApporteur(state),
                },
                ListePersonnes: [
                    {
                        TypePersonne: 'P',
                        RoleConducteur: 'P',
                        Souscripteur:
                            getDateNaissancePersonnePrincipaleDemandeTarifInit(state) &&
                            moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY').add(
                                18,
                                'years',
                            ) > moment()
                                ? false
                                : getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state) !== 'false',
                        DateNaissance:
                            getDateNaissancePersonnePrincipaleDemandeTarifInit(state) &&
                            moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY'),
                        ListePermis:
                            getListePermisPersonnePrincipaleDemandeTarifInit(state).length > 0
                                ? [
                                      {
                                          TypePermis: getPermisAMPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                          DatePermis:
                                              getPermisAMPersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                              moment(
                                                  getPermisAMPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                                  'DD/MM/YYYY',
                                              ),
                                      },
                                      {
                                          TypePermis: getPermisA1PersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                          DatePermis:
                                              getPermisA1PersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                              moment(
                                                  getPermisA1PersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                                  'DD/MM/YYYY',
                                              ),
                                      },
                                      {
                                          TypePermis: getPermisA2PersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                          DatePermis:
                                              getPermisA2PersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                              moment(
                                                  getPermisA2PersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                                  'DD/MM/YYYY',
                                              ),
                                      },
                                      {
                                          TypePermis: getPermisAPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                          DatePermis:
                                              getPermisAPersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                              moment(
                                                  getPermisAPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                                  'DD/MM/YYYY',
                                              ),
                                      },
                                      {
                                          TypePermis: getPermisBPersonnePrincipaleDemandeTarifInit(state).TypePermis,
                                          DatePermis:
                                              getPermisBPersonnePrincipaleDemandeTarifInit(state).DatePermis &&
                                              moment(
                                                  getPermisBPersonnePrincipaleDemandeTarifInit(state).DatePermis,
                                                  'DD/MM/YYYY',
                                              ),
                                      },
                                  ]
                                : null,
                        Civilite: getCivilitePersonnePrincipaleDemandeTarifInit(state),
                        Nom: getNomPersonnePrincipaleDemandeTarifInit(state),
                        Prenom: getPrenomPersonnePrincipaleDemandeTarifInit(state),
                        Adresse1: getAdresse1PersonnePrincipaleDemandeTarifInit(state),
                        Adresse2: getAdresse2PersonnePrincipaleDemandeTarifInit(state),
                        Adresse3: getAdresse3PersonnePrincipaleDemandeTarifInit(state),
                        Ville: getVillePersonnePrincipaleDemandeTarifInit(state),
                        CP: getCPPersonnePrincipaleDemandeTarifInit(state),
                        TelPortable: normalizeTel(getTelPortableSouscripteurTarif(state)),
                        NumPermis: getNumPermisSouscripteurTarif(state),
                        Email: getEmailSouscripteurTarif(state),
                        ValeurCrmMoto: getValeurCrmMotoPersonnePrincipaleDemandeTarifInit(state)
                            ? String(Number(getValeurCrmMotoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(2))
                            : null,
                        CrmMoto50Plus3Ans: getCrmMoto50Plus3AnsPersonnePrincipaleDemandeTarifInit(state),
                        ValeurCrmAuto: getValeurCrmAutoPersonnePrincipaleDemandeTarifInit(state)
                            ? String(Number(getValeurCrmAutoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(2))
                            : null,
                        CrmAuto50Plus3Ans: getCrmAuto50Plus3AnsPersonnePrincipaleDemandeTarifInit(state),
                        NbMoisAssuranceMoto: getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state)
                            ? Number(getNbMoisAssuranceMotoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(0)
                            : null,
                        NbMoisAssuranceAuto: getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state)
                            ? Number(getNbMoisAssuranceAutoPersonnePrincipaleDemandeTarifInit(state))?.toFixed(0)
                            : null,
                        BirthCountry: getBirthCountryPersonnePrincipaleDemandeTarifInit(state),
                        BirthCity: getBirthCityPersonnePrincipaleDemandeTarifInit(state),
                        BirthDepartment: getBirthDepartmentPersonnePrincipaleDemandeTarifInit(state),
                        BirthName: getBirthNamePersonnePrincipaleDemandeTarifInit(state),
                        ConducteurMineur: getConducteurMineurPersonnePrincipaleDemandeTarifInit(state) ?? false,
                        DejaClientAntecedents: getDejaClientAntecedentsPersonnePrincipaleDemandeTarifInit(state) ?? [],
                        NbMoisReferenceMoto: isDevisOrDevisOrange
                            ? getNbMoisReferenceMotoPersonnePrincipaleDemandeTarifInit(state)
                            : '24',
                        NbMoisReferenceAuto: isDevisOrDevisOrange
                            ? getNbMoisReferenceAutoPersonnePrincipaleDemandeTarifInit(state)
                            : '24',
                    },
                    !_.isEmpty(getTuteurDemandeTarifInit(state))
                        ? {
                              Souscripteur: true,
                              TypePersonne: 'P',
                              RoleConducteur: 'N',
                              DateNaissance:
                                  getDateNaissanceTuteurDemandeTarifInit(state) &&
                                  moment(getDateNaissanceTuteurDemandeTarifInit(state), 'DD/MM/YYYY'),
                              Civilite: getCiviliteTuteurDemandeTarifInit(state),
                              Nom: getNomTuteurDemandeTarifInit(state),
                              Prenom: getPrenomTuteurDemandeTarifInit(state),
                              BirthCountry: getBirthCountryTuteurDemandeTarifInit(state),
                              BirthCity: getBirthCityTuteurDemandeTarifInit(state),
                              BirthDepartment: getBirthDepartmentTuteurDemandeTarifInit(state),
                              BirthName: getBirthNameTuteurDemandeTarifInit(state),
                          }
                        : !_.isEmpty(getPersonneMoraleDemandeTarifInit(state))
                        ? {
                              Souscripteur: true,
                              TypePersonne: 'M',
                              RoleConducteur: 'N',
                              Civilite: getCivilitePersonneMoraleTarif(state),
                              Nom: getNomPersonneMoraleTarif(state),
                              LegalStatus: getLegalStatusPersonneMoraleTarif(state),
                              IdSociety: getIdSocietyPersonneMoraleTarif(state),
                          }
                        : _.isEmpty(getTuteurDemandeTarifInit) &&
                          getDateNaissancePersonnePrincipaleDemandeTarifInit(state) &&
                          moment(getDateNaissancePersonnePrincipaleDemandeTarifInit(state), 'DD/MM/YYYY').add(
                              18,
                              'years',
                          ) > moment()
                        ? {
                            Souscripteur: true,
                            TypePersonne: 'P',
                            RoleConducteur: 'N',
                            BirthCountry: getBirthCountryTuteurDemandeTarifInit(state),
                            BirthCity: getBirthCityTuteurDemandeTarifInit(state),
                            BirthDepartment: getBirthDepartmentTuteurDemandeTarifInit(state),
                            BirthName: getBirthNameTuteurDemandeTarifInit(state),
                          }
                        : undefined,
                ],
                Vehicule: {
                    Immatriculation: getImmatriculationVehiculeDemandeTarifInit(state),
                    PossessionPlusDe3Mois: _.includes(['true', '1', true], getPossessionPlusDe3MoisVehiculeDemandeTarifInit(state)) ? '1' : '0',
                    VehiculeImmatricule: getVehiculeImmatriculeVehiculeDemandeTarifInit(state)
                        ? '1'
                        : getVehiculeImmatriculeVehiculeDemandeTarifInit(state),
                    SituationVehicule: isDevisOrDevisOrange ? '1' : null,
                    FamilleProduit: 'Moto',
                    IdentifiantSRA: getIdentifiantSRAVehiculeDemandeTarifInit(state),
                    DateAchatVehicule: getDateAchatVehiculeDemandeTarif(state) ? moment(getDateAchatVehiculeDemandeTarif(state), 'DD/MM/YYYY') : '',
                    DateMEC: getDateMECVehiculeDemandeTarif(state)
                        ? moment(getDateMECVehiculeDemandeTarif(state), 'DD/MM/YYYY')
                        : '',
                    Assure3DerniersMois: _.includes(
                        ['true', '1', true],
                        getAssure3DerniersMoisVehiculeDemandeTarifInit(state),
                    )
                        ? '1'
                        : _.includes(['false', '0', false], getAssure3DerniersMoisVehiculeDemandeTarifInit(state))
                        ? '0'
                        : undefined,
                    UsageVehicule: getUsageVehiculeDemandeTarif(state),
                    UsageNonLivraison: getUsageNonLivraisonVehiculeDemandeTarifInit(state),
                    ValeurVehicule: getValeurVehiculeVehiculeDemandeTarifInit(state),
                    CarteGriseFrancaise: getCarteGriseFrancaiseVehiculeDemandeTarifInit(state) && '1',
                    VilleGarage: getVilleGarageVehiculeDemandeTarifInit(state),
                    CodePostalGarage: getCodePostalGarageVehiculeDemandeTarifInit(state),
                    TitulaireCarteGrise: true,
                },
                Antecedents: {
                    SuspensionPermis: getSuspensionPermisAntecedentsDemandeTarifInit(state),
                    DateSuspensionPermis:
                        getAntecedentsDemandeTarifInit(state).DateSuspensionPermis &&
                        moment(getAntecedentsDemandeTarifInit(state).DateSuspensionPermis, 'DD/MM/YYYY'),
                    NbMoisSuspensionPermis: getAntecedentsDemandeTarifInit(state).NbMoisSuspensionPermis,
                    AnnulationPermis: getAnnulationPermisAntecedentsDemandeTarifInit(state),
                    DateAnnulationPermis:
                        getAntecedentsDemandeTarifInit(state).DateAnnulationPermis &&
                        moment(getAntecedentsDemandeTarifInit(state).DateAnnulationPermis, 'DD/MM/YYYY'),
                    CondamnationAlcoolemie: getCondamnationAlcoolemieAntecedentsDemandeTarifInit(state),
                    TxAlcool: getAntecedentsDemandeTarifInit(state).TxAlcool,
                    CondamnationStupefiants: getCondamnationStupefiantsAntecedentsDemandeTarifInit(state),
                    CondamnationDelitFuite: getCondamnationDelitFuiteAntecedentsDemandeTarifInit(state),
                    CondamnationConduiteSansAssurance:
                        getCondamnationConduiteSansAssuranceAntecedentsDemandeTarifInit(state),
                    ResiliationAssureur: getAntecedentsDemandeTarifInit(state).ResiliationAssureur ? '1' : '0',
                    MotifResiliationAssureur: getAntecedentsDemandeTarifInit(state).MotifResiliationAssureur,
                    DateResiliationAssureur:
                        getAntecedentsDemandeTarifInit(state).DateResiliationAssureur &&
                        moment(getAntecedentsDemandeTarifInit(state).DateResiliationAssureur, 'DD/MM/YYYY'),
                    ListeSinistres: _.map(getListeSinistresAntecedentsDemandeTarifInit(state), (s) => {
                        s.DateSinistre = moment(s.DateSinistre, 'DD/MM/YYYY');
                        if (s.DateSinistre.isValid()) {
                            return { ...s };
                        } else {
                            return {};
                        }
                    }),
                },
                DevoirConseil: {
                    DevoirVol: getDevoirVolDevoirConseilDemandeTarifInit(state),
                    DevoirDommage: getDevoirDommageDevoirConseilDemandeTarifInit(state),
                    DevoirASS2: getDevoirASS2DevoirConseilDemandeTarifInit(state),
                    DevoirASS3: getDevoirASS3DevoirConseilDemandeTarifInit(state),
                    DevoirIC: getDevoirICDevoirConseilDemandeTarifInit(state),
                    DevoirACCESSEQP: getDevoirAccEqpDevoirConseilDemandeTarifInit(state),
                    DevoirMontantACCESSEQP: getDevoirMontantAccEqpDevoirConseilDemandeTarifInit(state),
                    DevoirRAFRA: getDevoirRachatDevoirConseilDemandeTarifInit(state),
                },
            },
        },
        formValues: getFormValues('devis')(state),
    };
};

const mapDispatchToProps = {
    postFill,
    setDevisOrSubscribe,
    postSaveDevis,
    postPay,
    changeValue: (field, value) => change('devis', field, value),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { step, maxStep, query, querySaveDevis, queryTarif, devisOrSubscribe, formValues, queryPay, url_pay } =
        stateProps;
    const { postFill, postSaveDevis, postPay } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        nextStep: (form) => postFill({ step: step + 1, maxStep, form, query, urlReloadTarif: queryTarif }),
        postSaveDevis: (form) => postSaveDevis({ form, query: querySaveDevis, devisOrSubscribe, step }),
        previousStep: (currentStep = null) =>
            postFill({
                step: currentStep ? currentStep - 1 : step - 1,
                maxStep,
                form: formValues,
                query,
                urlReloadTarif: queryTarif,
            }),
        postPaySubmit: (form) => postPay({ query: queryPay, form, url_pay }),
    };
};

const InitFormulaireContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devis',
        touchOnChange: true,
    }),
)(InitFormulaire);

export default InitFormulaireContainer;
