import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { FETCH_MARQUES_VEHICULIER } from '../../../../../redux/actions/app/vehiculier/vehiculier.actions.js';
import { getLoadingEntity } from '../../../../../redux/selectors/ui/ui.selectors.js';
import Vehiculier from './Vehiculier.jsx';
import {getApporteur1Init, getApporteur2Init} from "../../../../../redux/selectors/init/init.selectors.js";

const mapStateToProps = (state) => {
    const selector = formValueSelector('devis');

    return {
        typeVehicule: selector(state, `typeVehicule`),
        marque: selector(state, `marqueVehicule`),
        cylindree: selector(state, `cylindreeVehicule`),
        is50: selector(state, `typeVehicule`) === '50',
        loadedMarques: getLoadingEntity(state, FETCH_MARQUES_VEHICULIER) === false,
        isDucati: getApporteur1Init(state)?.codeBelair === '323284',
        isMobion: getApporteur2Init(state) ? getApporteur2Init(state)?.codeBelair === '864160' : false
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            changeValue: (field, value) => change('devis', field, value),
        },
        dispatch,
    );

const VehiculierContainer = connect(mapStateToProps, mapDispatchToProps)(Vehiculier);

export default VehiculierContainer;
